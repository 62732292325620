@import '~vars.css';

.input {
  display: inline-block;
  word-break: break-word;  
  outline: none;
}

.input:focus {
  outline: none;
}

.input:empty::before {
  content: attr(placeholder);
  color: grey;
  font-style: italic;
}

.wrapper {
  
}

.error {
  border-color: var(--error-color);
  /* margin-bottom: 40px; */
}

.errorText {
  color: var(--error-color);
  font-size: 16px;
  content: attr(data-error);
  margin-top: 8px;
  text-transform: none;
}