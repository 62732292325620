@import '~vars.css';

.wrapper {
  background-image: url('/img.png');
  background-size: cover;
  background-position: center center;
}

.inner {
  max-width: 1024px;
  margin: 0 auto;
  color: var(--text-color);
  padding: 200px 20px 40px;
  color: white;
}

.tagline {
  text-align: center;
  font-weight: 300;
  font-size: 24px;
  line-height: 30px;
  max-width: 600px;
  margin: 0 auto;
}

.tagline strong {
  font-weight: 600;
}

.button {
  margin-top: 40px;
  text-align: center;
}

.button a {
  position: relative;
  display: inline-block;
  background: var(--yellow-color);
  font-size: 24px;
  font-weight: 400;
  padding: 20px 68px 20px 20px;
  box-sizing: border-box;
  border-radius: 60px;
  cursor: pointer;
  color: black;
  text-decoration: none;
}

.button a::after {
  content: '';
  position: absolute;
  right: 24px;
  top: 27px;
  border: solid black;
  border-width: 0 2px 2px 0;
  padding: 5px;
  z-index: 1000;
  transform: rotate(-45deg);
}

.description {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 16px;
  line-height: 24px;
  margin-top: 190px;
}

.description > div:not(:first-child) {
  margin-left: 112px;
}

.description a {
  font-style: italic;
  text-decoration: none;
  border-bottom: 1px solid;
  color: inherit;
}

@media (max-width: 768px) {
  .description {
    display: block;
  }

  .description > div:not(:first-child) {
    margin-left: 0;
    margin-top: 20px;
  }
}