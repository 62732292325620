@import '~vars.css';

.wrapper {
  max-width: 1024px;
  margin: 0 auto;
  color: var(--text-color);
  margin-top: 50px;
  overflow: auto;
  padding: 0 20px;
  margin-bottom: 60px;
}

.startDate {
  font-size: 24px;
  font-weight: 300;
}

.diagram {
  position: relative;
  margin-top: 16px;
}

.nodes {
  display: flex;
  align-items: stretch;
}

.column {
  background: var(--grey-light-color);
  flex: 1;
  display: flex;
  flex-direction: column;
}

.column:not(:first-child) {
  margin-left: 2px;
}

.columnName {
  font-size: 12px;
  font-weight: 600;
  color: var(--grey-dark-color);
  padding: 10px 10px 0;
  flex-shrink: 0;
  flex-grow: 0;
}

.columnNodes {
  margin-top: 10px;
  padding: 30px 26px;
  flex-basis: 100%;
  display: block;
}

.node {
  background: white;
  height: 100px;
  border-radius: 3px;
  padding: 7px;
  box-sizing: border-box;
  cursor: pointer;
  border: 3px solid transparent;
  width: 150px;
  margin: 0 auto;
  z-index: 90;
  position: relative;
  animation: scale-node .2s;
  transform-origin: center center;
  margin-top: 10px;
  word-wrap: break-word;
}

.emptyNode {
  height: 100px;
  margin-top: 10px;
}

.createNode {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 20px;
  cursor: pointer;
  font-size: 40px;
  color: var(--grey-dark-color);
  transition: background .2s linear;
  font-weight: 300;
}

.createNode:hover {
  background: white;
}

.dragOver {
  background: var(--blue-color);
}

.emptyNode div {
  pointer-events: none;
}

.emptyNode:first-child, .node:first-child {
  margin-top: 0;
}

@keyframes scale-node {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.node:hover, .selectedNode {
  border-color: var(--blue-color);
}


.nodeValue {
  font-size: 34px;
  font-weight: 500;
}
.nodeValueCalculated {
  font-size: 20px;
  line-height: 34px;
  color: var(--grey-dark-color);
}
.nodeLabel {
  font-size: 11px;
  line-height: 13px;
  font-weight: 500;
  margin-top: 2px;
}

.link {
  position: absolute;
  transform-origin: center left;
  height: 10px;
  cursor: pointer;
}


.link:hover .nodeRemove {
  display: block
}

.node:hover .nodeRemove {
  display: block
}

.linkFill {
  position: absolute;
  height: 1px;
  top: 2px;
  background: black;
  z-index: 80;
}

.errorLink .linkFill {
  background: red;
}

.linkArrow {
  position: absolute;
  bottom: -3px;
  right: 1px;
  border: solid;
  border-width: 0 1px 1px 0;
  transform: rotate(-45deg);
  padding: 3px;
}

.link:hover > .linkFill, .selectedLink {
  background: var(--blue-color);
  color: var(--blue-color);
  z-index: 89;
}

.nodeRemove {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 14px;
  height: 14px;
  padding: 2px;
  border-radius: 50%;
  border: 2px solid;
  color: var(--text-color);
  background: white;
  display: none;
}

.nodeRemove:hover {
  color: var(--blue-color);
}

.linkRemove {
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
  z-index: 100;
}

.createArrow {
  position: absolute;
  top: 50%;
  width: 16px;
  height: 16px;
  transform: translateY(-50%);
  color: var(--text-color);
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid white;
  font-size: 16px;
}

.node:hover .createArrow, .createArrowSelected {
  border-color: var(--blue-color);
}

.createArrow:hover {
  background: var(--blue-color);
}

.createArrowTarget {
  left: -24px;
}

.createArrowSource {
  right: -24px;
}

.errorNode {
  color: red;
}
/* .link::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 8px;
  left: -8px;
  color: inherit;
  background: inherit;
}

.link::after {
  content: '';
  position: absolute;
  height: 100%;
  width: 8px;
  right: -8px;
  color: inherit;
  background: inherit;
} */
