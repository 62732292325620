@import '~vars.css';

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 500;
  pointer-events: none;
}


.inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 400px;
  background: white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
  padding: 23px 20px;
  box-sizing: border-box;
  overflow: auto;
  transform-origin: center center;
  transform: translateX(400px);
  transition: transform 0.25s linear;
}

.showed {
  pointer-events: all;
}

.showed .inner {
  transform: translateX(0);
}

.type {
  color: var(--blue-color);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.title {
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
  margin-top: 23px;
}

.content {
  font-size: 14px;
  line-height: 20px;
  margin-top: 20px;
}

.close {
  position: absolute;
  top: 16px;
  right: 20px;
  cursor: pointer;
  z-index: 100;
}
.actions {
  margin-top: 20px;
}

.action {
  background: var(--grey-light-color);
  border: none;
  color: var(--text-color);
  padding: .5em 1em;
  border-radius: .7em;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color .2s, color .2s;
  text-decoration: none;
  margin-left: 4px;
}

.action:hover {
  background-color: var(--blue-color);
  color: white;
}

.action:active {
  outline: none;
}

.formula {
  
}

.valueInput {
  font-weight: 600;
}

.withSidebar {
  overflow: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
  opacity: 0.3;
  display: none;
  cursor: pointer;
  transition: opacity .2s;
}

.overlay:hover {
  opacity: 0.4;
}

.showed .overlay {
  display: block;
}