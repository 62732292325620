@import '~vars.css';


.app {
  position: relative;
  max-width: 1024px;
  padding: 0 20px;
  margin: 0 auto;
}
.logo {
  padding: 20px 0;
  color: var(--grey-color);
  font-family: inherit;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.01em;
  opacity: 0.8;
}

.logo a {
  color: var(--grey-color);
  text-decoration: none;
}

.logo a:hover {
  color: var(--blue-color);
}