@import '~vars.css';

.wrapper {
  max-width: 1024px;
  margin: 0 auto;
  color: var(--text-color);
}

.header {
  position: relative;
  /* padding-top: 40px; */
}

.name {
  font-size: 48px;
  font-weight: 500;
}

.tagline {
  font-weight: bold;
  margin-top: 24px;
  font-size: 20px;
}

.website {
  display: flex;
  align-items: flex-start;
  margin-top: 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  text-transform: lowercase;
  color: var(--text-color);
}

.website a {
  color: var(--text-color);
}

.website span {
  margin-right: 4px;
}

.content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 60px;
}

.block {
  flex-basis: 50%;
}

.block:not(:first-child) {
  margin-left: 112px;
}

.blockTitle {
  font-size: 24px;
  font-weight: 300;
  line-height: 29px;
}

.blockText {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 20px;
}

.actions {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 16px;
  display: flex;
}

.action {
  margin-left: 4px;
}



.projectSlug {
  margin-top: 24px;
}

.projectSlugInput {
  font-size: 22px;
  border: none;
  padding: 0;
}

.projectSlugInput:focus {
  outline: none;
}

.startDatePicker {
  font-size: 24px;
  padding: 8px;
  margin-top: 20px;
  border: 1px solid var(--blue-color);
}

.description {
  margin-top: 40px;
}

.published {
  margin-top: 28px;
  display: flex;
  align-items: center;
}

.published span {
  padding: 0;
  margin-right: 4px;
}

@media (max-width: 768px) {
  .content {
    display: block;
  }

  .block:not(:first-child) {
    margin-left: 0;
    margin-top: 20px;
  }
}