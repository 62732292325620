/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
font-family: 'SF Pro';
font-style: normal;
font-weight: normal;
src: local('SF Pro Regular'), url('SF-Pro-Display-Regular.otf') format('otf');
}


@font-face {
font-family: 'SF Pro Italic';
font-style: normal;
font-weight: normal;
src: local('SF Pro Italic'), url('SF-Pro-Display-RegularItalic.otf') format('otf');
}


@font-face {
font-family: 'SF Pro Light';
font-style: normal;
font-weight: normal;
src: local('SF Pro Light'), url('SF-Pro-Display-Light.otf') format('otf');
}


@font-face {
font-family: 'SF Pro Light Italic';
font-style: normal;
font-weight: normal;
src: local('SF Pro Light Italic'), url('SF-Pro-Display-LightItalic.otf') format('otf');
}


@font-face {
font-family: 'SF Pro Medium';
font-style: normal;
font-weight: normal;
src: local('SF Pro Medium'), url('SF-Pro-Display-Medium.otf') format('otf');
}


@font-face {
font-family: 'SF Pro Medium Italic';
font-style: normal;
font-weight: normal;
src: local('SF Pro Medium Italic'), url('SF-Pro-Display-MediumItalic.otf') format('otf');
}


@font-face {
font-family: 'SF Pro Semibold';
font-style: normal;
font-weight: normal;
src: local('SF Pro Semibold'), url('SF-Pro-Display-Semibold.otf') format('otf');
}


@font-face {
font-family: 'SF Pro Semibold Italic';
font-style: normal;
font-weight: normal;
src: local('SF Pro Semibold Italic'), url('SF-Pro-Display-SemiboldItalic.otf') format('otf');
}


@font-face {
font-family: 'SF Pro Bold';
font-style: normal;
font-weight: normal;
src: local('SF Pro Bold'), url('SF-Pro-Display-Bold.otf') format('otf');
}


@font-face {
font-family: 'SF Pro Bold Italic';
font-style: normal;
font-weight: normal;
src: local('SF Pro Bold Italic'), url('SF-Pro-Display-BoldItalic.otf') format('otf');
}


@font-face {
font-family: 'SF Pro Heavy';
font-style: normal;
font-weight: normal;
src: local('SF Pro Heavy'), url('SF-Pro-Display-Heavy.otf') format('otf');
}


@font-face {
font-family: 'SF Pro Heavy Italic';
font-style: normal;
font-weight: normal;
src: local('SF Pro Heavy Italic'), url('SF-Pro-Display-HeavyItalic.otf') format('otf');
}
