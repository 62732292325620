@import '~vars.css';

.wrapper {
  position: relative;
  display: block;
  width: 100%;
}

.input {
  position: relative;
  display: block;
  width: 100%;
  border: 1px solid var(--blue-color);
  padding: 10px 12px;
  font-family: SF Pro;
  font-size: 16px;
  box-sizing: border-box;
}

.input::placeholder {
  color: graytext;
  font-weight: 400;
}

.input:disabled {
  border-color: var(--text-color);
  color: var(--text-color);
  background: var(--grey-light-color);
}


.error {
  border-color: var(--error-color);
  /* margin-bottom: 40px; */
}

.errorText {
  color: var(--error-color);
  font-size: 16px;
  content: attr(data-error);
  margin-top: 8px;
}

.title {
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 4px;
  color: graytext;
}