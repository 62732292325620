@import '~vars.css';

.wrapper {
  text-align: center;
}

.text {
  font-size: 28px;
  color: var(--grey-dark-color);
  margin-top: 32px;
}

.btn {
  font-size: 20px;
  margin-top: 32px;
}