
@import '~vars.css';
.wrapper {
  position: relative;
  outline: 1px solid transparent;
}

.edit {
  outline-color: var( --blue-color);
}

.actions {
  display: flex;
  position: absolute;
  top: -24px;
  background: white;
  width: 174px;
  display: none;
}

.actions div {
  flex-basis: 32px;
  flex-shrink: 0;
  flex-grow: 0;
  height: 24px;
  text-align: center;
  background-color: var(--grey-light-color);
  margin-right: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.actions div:hover {
  background-color: var(--blue-color);
  color: white;
}

.selected {
  background-color: var( --blue-color) !important;
  color: white;
}

.input:empty:focus::before {
  content: "";
}

.oneLine trix-editor {
  min-height: auto !important;
}

.error {
  outline-color: var(--error-color);
  margin-bottom: 40px;
}

.wrapper::after {
  color: var(--error-color);
  font-size: 16px;
  content: attr(data-error);
  position: absolute;
  bottom: 0;
  transform: translateY(calc(100% + 4px));
}

.title {
  position: absolute;
  top: 0;
  transform: translateY(calc(-100% - 6px));
  font-size: 14px;
  font-weight: bold;
  color: graytext;
}