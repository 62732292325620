@import '~vars.css';

.row {
  margin: 16px 0;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalInner {
  background-color: white;
  border: 2px solid var(  --grey-color);
  padding: 12px;
  border-radius: 4px;
}

.modalText {
  font-size: 20px;
}

.modalBtn {
  margin-top: 12px;
}