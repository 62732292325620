body {
  margin: 0;
  padding: 0;
  font-family: 'SF Pro', 'Arial', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

trix-editor {
  border: none !important;
}

.trix-content  i, .trix-content em, trix-editor  i, trix-editor em {
  font-style: italic;
}

.trix-content b, .trix-content strong, trix-editor b, trix-editor strong {
  font-weight: bold;
}

.trix-content h1, trix-editor h1 {
  font-size: 1.6em;
  font-weight: 600;
}

.trix-content a, trix-editor a {
  color: var( --blue-color);
}

.trix-content sub, trix-editor sub {
  vertical-align: sub;
  font-size: smaller;
}

.trix-content sup, trix-editor sup {
  vertical-align: super;
  font-size: smaller;
}

.trix-content ul, trix-editor ul {
  list-style-type: disc;
  padding-left: 0.75em;

}
.trix-content ol, trix-editor ol {
  list-style-type: decimal;
  padding-left: 0.75em;
}

.trix-content li, trix-editor li {

}

.trix-content blockquote, trix-editor blockquote {
  font-size: 1.2em;
  background: #f9f9f9;
  border-left: 0.3em solid #ccc;
  margin: 1.5em 0.3em;
  padding: 0.5em 0.3em;
  quotes: "\201C""\201D""\2018""\2019";
}

.trix-content blockquote::before, trix-editor blockquote::before {
  color: #ccc;
  content: open-quote;
  font-size: 2em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

.trix-content blockquote::after, trix-editor blockquote::after {
  color: #ccc;
  content: close-quote;
  font-size: 2em;
  line-height: 0.1em;
  margin-left: 0.25em;
  vertical-align: -0.4em;
}


trix-toolbar {
  font-size: 12px;
}

.trix-button-group {
  margin-bottom: 0 !important;
}