@import '~vars.css';

.form {
  position: relative;
  margin: 0 auto;
  max-width: 600px;
  color: var(--text-color);
}

.title {
  font-size: 20px;
  text-transform: uppercase;
  color: var(--grey-dark-color);
}

.error {
  color: var(--error-color);
  margin-bottom: 16px;
  padding: 0 4px;
}

.actions {

}

.actions button, .actions a {
  margin-right: 8px;
}