@import '~vars.css';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 0;
  right: 20px;
  height: 70px;
}

.exit, .projects {
  color: var(--text-color);
  cursor: pointer;
}
.exit:hover, .projects:hover {
  color: var(--blue-color);
}

.projects {
  margin-right: 10px;
}

.menu {
  position: absolute;
  right: 32px;
  top: 24px;
  width: 200px;
  z-index: 100;
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2);
}

.menuRow {
  cursor: pointer;
  padding: 8px 16px;
  font-size: 14px;
  color: var(--text-color);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background: var(--grey-light-color);
  display: block;
  text-decoration: none;
}
.menuRow:hover {
  color: white;
  background: var(--blue-color);
}

.menuRowNew {
  font-weight: 600;
}
